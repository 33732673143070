<template>
    <!-- 选择时间段 -->
    <div class="selectTimePeriod">
        <div :style="{'color': item.check ? color.color : '', 'backgroundColor': item.check ? color.bagColor : ''}" class="timeItem" v-for="(item, index) in timePeriod" :key="index" @click="selectTime(item, index)">{{ item.value }}</div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            timePeriod: [
                {
                    check: true,
                    value: '近1周'
                },
                {
                    check: false,
                    value: '近1月'
                },
                {
                    check: false,
                    value: '近3月'
                },
                {
                    check: false,
                    value: '近6月'
                },
                {
                    check: false,
                    value: '近1年'
                }
            ]
        };
    },
    props:['color'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        selectTime(item, index){
            this.timePeriod.map((el, i) => {
                if(index == i){
                    el.check = true;
                } else {
                    el.check = false;
                }
            })

            this.$emit('changeItem', item);
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.selectTimePeriod {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 99999;
    padding-bottom: 10px;
    border-bottom: 1px solid #CDCBD8;
    .timeItem {
        width: 19%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: #808080;
    }
}
</style>